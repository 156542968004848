import EtPersonalJson from "@/ts/components/EtPersonalJson";
import ApiHandler from "@/ts/components/Handlers/ApiHandler";
import {openCartPreview} from "@/ts/components/Warenkorb";

export function selectVariant(
    masterId: number,
    variantId: number,
    selector: string,
    context: HTMLElement | Document = document
): void {
    const divElements: NodeListOf<HTMLDivElement> = context.querySelectorAll(`.vmaster${masterId} .vproduct,.vmaster${masterId}.vproduct`);

    // window.selectedVariant = variantId;

    // Toggle vproduct class
    divElements.forEach((element: HTMLDivElement) => {
        const isMatchingVariant: boolean = element.classList.contains("vproduct" + variantId);
        element.hidden = !isMatchingVariant;
        const input: HTMLInputElement | null = element.querySelector("input");
        if (input) {
            input.disabled = !isMatchingVariant;
        }
    });

    // Toggle dropdown
    if (selector === "dropdown") {
        const selectElement: HTMLSelectElement | null = context.querySelector(`select[data-master-id="${masterId}"]`);
        if (selectElement) {
            const options: NodeListOf<HTMLOptionElement> = selectElement.querySelectorAll("option");
            options.forEach((option: HTMLOptionElement) => {
                option.selected = option.value === variantId.toString();
            });
        }
    } else {
        const buttons: NodeListOf<Element> = context.querySelectorAll(".master-" + masterId);
        buttons.forEach((button: Element) => {
            if (button instanceof HTMLButtonElement) {
                const isSelected: boolean = button.getAttribute("data-var-id") === variantId.toString();
                button.dataset.selected = isSelected.toString();
            }
        });
    }

    if (window.trackPeeriusProduct && typeof window.trackPeeriusProduct === "function") {
        window.trackPeeriusProduct(variantId);
    }

    // TODO: globales event feuern
    document.dispatchEvent(new CustomEvent("et.variantChanged", {detail: {masterId, variantId}}));
}

export function elementArtikelChangeVariant(selectElement: HTMLSelectElement, activateVariantId?: number): boolean {
    if (!(selectElement instanceof HTMLSelectElement)) {
        return false;
    }

    const elementArtikel: HTMLElement | null = selectElement.closest(".js_element_artikel_base");
    if (!elementArtikel) {
        console.error("elementArtikelChangeVariant: unable to find element_artikel", {selectElement});
        return false;
    }

    let activateVariantKey = selectElement.value;
    calcTotalPrice(activateVariantKey);
    // use the given value instead?
    if (typeof activateVariantId === "number" && activateVariantId > 0) {
        activateVariantKey = activateVariantId.toString();
        // test if activateVariantId exists in selectElement options
        const option = Array.from(selectElement.options).find((option) => option.value === activateVariantKey);

        if (!option || option.selected) {
            return false;
        }

        // update the selected option
        selectElement.value = activateVariantKey;
    }

    if (activateVariantKey.length) {
        if (selectElement.dataset?.lastSelectedValue === activateVariantKey) {
            return false;
        }
        selectElement.dataset.lastSelectedValue = activateVariantKey;

        const buttonElement: HTMLButtonElement | null = elementArtikel.querySelector("button[name^='add']");
        if (buttonElement) {
            buttonElement.value = activateVariantKey;
            buttonElement.disabled = false;
        }

        const targetClass = "vproduct" + activateVariantKey;
        elementArtikel.querySelectorAll(".vproduct").forEach((variantElement) => {
            if (variantElement instanceof HTMLElement) {
                variantElement.hidden = !variantElement.classList.contains(targetClass);
            }
        });

        return true;
    } else {
        console.debug("elementArtikelChangeVariant: activateVariantKey is empty", {activateVariantKey, selectElement});
    }

    return false;
}

function calcTotalPrice(varId: string): void {
    if (typeof active_variants === 'object' && typeof item_prices === 'object' && typeof currency_symbol === 'string') {
        // get active IDs first
        let master_id = '0';
        for(var i in active_variants) {
            for(var j in active_variants[i]) {
                if (j == varId) {
                    master_id = i;
                }
            }
        }
        for(var i in active_variants[master_id]) {
            if (i == varId) {
                active_variants[master_id][i] = true;
            } else {
                active_variants[master_id][i] = false;
            }
        }
        // now see if every product has a selected variant
        let all_active = true;
        for(var i in active_variants) {
            let has_active_variant = false
            for(var j in active_variants[i]) {
                if (active_variants[i][j]) {
                    has_active_variant = true;
                }
            }
            if (!all_active || !has_active_variant) {
                all_active = false;
            }
        }
        // calculate new total price
        if (all_active) {
            let total_price = 0.;
            for(var i in active_variants) {
                for(var j in active_variants[i]) {
                    if (active_variants[i][j]) {
                        total_price += item_prices[j];
                    }
                }
            }
            total_price = Math.round(total_price * 100) / 100;
            document.querySelectorAll('.xref_total_price_from').forEach(function (elem){
                if (!elem.classList.contains('d-none')) {
                    elem.classList.add('d-none');
                }
            });
            document.querySelectorAll('.xref_total_price').forEach(function (elem){
                if (elem.innerText.includes(',')) {
                    elem.innerText = total_price.toString().replace('.', ',') + ' ' + currency_symbol;
                } else {
                    elem.innerText = total_price.toString() + ' ' + currency_symbol;
                }
            });
        }
    }
}

export async function elementArtikelAddToCart(buttonElement: HTMLButtonElement) {
    try {
        if (!(buttonElement instanceof HTMLButtonElement)) {
            console.error("elementArtikelAddToCart: buttonElement is not an HTMLButtonElement", {buttonElement});
            return false;
        }

        const elementArtikel: HTMLElement | null = buttonElement.closest(".js_element_artikel_base");
        if (!elementArtikel) {
            console.error("elementArtikelAddToCart: unable to find element_artikel", {elementArtikel});
            return false;
        }

        let buttonAppendix = '';
        if (buttonElement.name.startsWith('add')) {
            buttonAppendix = buttonElement.name.replace('add', '');
        }
        let quantityElement: HTMLInputElement | null = elementArtikel.querySelector(`input[name="menge${buttonAppendix}"]`);
        if(!quantityElement) {
            quantityElement = elementArtikel.querySelector(`input[name="qty"]`);
        }
        if (!quantityElement) {
            console.error("elementArtikelAddToCart: unable to find quantity input", {quantityElement});
            return false;
        }

        const variantSelectElement: HTMLElement | null = buttonElement.parentElement.querySelector(".element_artikel__variant_select");
        let productId = Number(variantSelectElement?.dataset.lastSelectedValue ?? 0);
        if(productId <= 0) {
            productId = Number(buttonElement.value ?? 0);
        }
        const quantity = Number(quantityElement.value);
        if (productId <= 0 || quantity <= 0) {
            console.warn("Invalid product id or quantity", {productId, quantity});
            return false;
        }

        const urlParams = [
            "ac_type=warenkorb",
            "ac_name=addartikel",
            "ac_id=" + buttonElement.value,
            "praesenz=" + window.etData.presenceId,
            "vw_type=warenkorb",
        ];
        const url = "/index.php?" + urlParams.join("&");

        buttonElement.disabled = true;
        showSpinner();

        await ApiHandler.getJsonFetch(
            {
                service: "getViewContent",
                url: encodeURI(url),
                praesenz: String(window.etData.presenceId)
            },
            {
                menge0: quantity.toString(),
                ac_id0: productId.toString(),
                add0: productId.toString(),
            });
        if (buttonElement.dataset.gtm) {
            let gtmData = JSON.parse(buttonElement.dataset.gtm);
        }
        triggerTmAddEvent(productId, quantity);
        EtPersonalJson.getInstance()
            .fetchData(false)
            .then(() => {
                document.querySelectorAll("#layout_header_button_cart").forEach((element) => {
                    if (element instanceof HTMLButtonElement && element.getAttribute("aria-expanded") == "false") {
                        element.click();
                    }
                });
            })
            .finally(() => {
                hideSpinner();
                buttonElement.disabled = false;
            });

    } catch (error) {
        console.error("elementArtikelAddToCart", {error});
        hideSpinner();
        buttonElement.disabled = false;
    }
}

export function formSubmitHandler_AddToCart_MultipleProducts(event: SubmitEvent) {
    event.preventDefault();

    const formElement = event.target;
    if (!(formElement instanceof HTMLFormElement)) {
        throw new Error("Invalid formElement");
    }

    const productList = new Map();
    const formData = new FormData(formElement);
    formData.forEach((value, key) => {
        // add missing ac_id/menge fields (required operation)
        if (key.startsWith('add')) {
            const intValue = Number(value);
            const fieldKey = Number(key.replace('add', ''));
            if (!(Number.isFinite(fieldKey) && fieldKey > 0)) {
                throw new Error(`Invalid fieldKey '${fieldKey}' from '${key}' in form data`);
            }

            const acIdKey = 'ac_id' + fieldKey;
            if (!formData.has(acIdKey) && intValue > 0) {
                formData.set(acIdKey, intValue.toString());
            }

            const qtyKey = 'menge' + fieldKey;
            if (!formData.has(qtyKey)) {
                formData.set(qtyKey, '1');
            }

            productList.set(formData.get(acIdKey), formData.get(qtyKey));
        }
    });
    if (productList.size) {
        addMultipleProductsToCart(productList);
    }
}

/**
 * Add multiple products to cart
 *
 * @param productList Map<b_artikel_id:number, quantity:number>
 */
export async function addMultipleProductsToCart(productList: Map<number, number>) {
    let postParams = {};
    let i = 1;
    productList.forEach((quantity, b_artikel_id) => {
        postParams[`add${i}`] = b_artikel_id.toString();
        postParams[`ac_id${i}`] = b_artikel_id.toString();
        postParams[`menge${i++}`] = quantity.toString();
    });

    const requestParams = {
        service: "getViewContent",
        url: encodeURI('/index.php?ac_type=warenkorb&ac_name=addartikel&vw_type=warenkorb&praesenz=' + window.etData.presenceId.toString()),
        praesenz: window.etData.presenceId.toString(),
    };

    showSpinner();
    ApiHandler.getJsonFetch(requestParams, postParams, {cache: "no-cache"})
        .then((response) => {
            if (response && response?.ok) {
                return response.json();
            }
            throw new Error("Invalid response");
        })
        .then(async () => {
            if (window.trackPeeriusCart && typeof window.trackPeeriusCart === "function") {
                console.debug('addMultipleProductsToCart trackPeeriusCart', {productList});
                productList.forEach((quantity, b_artikel_id) => {
                    window.trackPeeriusCart(b_artikel_id, quantity);
                });
            }
            await EtPersonalJson.getInstance().fetchData(false).then(openCartPreview);
        })
        .catch((error) => {
            console.error("formSubmitHandler_AddToCart_MultipleProducts error", {error});
        })
        .finally(() => {
            hideSpinner();
        });
}

export function updateInputField(element, gte1, gte2, gte3, id) {
    let value = parseFloat(element.value);
    let step = parseFloat(element.step);
    let min = parseFloat(element.min);
    let max = parseFloat(element.max);
    let ticks = (value - min) / step;

    if (Math.abs(ticks - Math.round(ticks)) > 0.01) {
        value = min + (Math.floor(ticks) * step);
        window.showTooltip(element, gte1);
    } else if (value < min) {
        value = min;
        window.showTooltip(element, gte2);
    } else if (value > max) {
        value = max;
        window.showTooltip(element, gte3);
    }

    let finalValue = value.toFixed(2).replace(/\.?0*$/, '');
    element.value = finalValue;
    document.querySelectorAll(`.wk_menge_${id}`).forEach(el => el.value = finalValue);
}