import FrontendHandler from "@/ts/components/Handlers/FrontendHandler";
import type EtDataType from "@/ts/components/Types/EtDataType";
import EtSlider from "@/ts/components/EtSlider";
import EtConsole from "@/ts/components/EtConsole";
import {initAllElementArtikel} from "@/ts/elements/artikel/element_artikel";

export type WidgetDataList = WidgetData[];

type WidgetData = {
    "widget": string, // eg "home" or "home2"
    "alias": string,
    "position": string, // eg "homejson_ip" or "homejson_ip2"
    "recs": WidgetDataRec[]
}

type WidgetDataRec = {
    "prices": {
        "EUR": {
            "unitPrice": number,
            "salePrice": number
        }
    },
    "url": string,
    "img": string,
    "id": number,
    "title": string,
    "refCode": string
}

type WidgetConfig = {
    selector: string,
    template: string
}

const etConsole = new EtConsole();

/**
 * Funktion kopiert aus dev/customer_vbs/custom/module/public/vbsr/vbsr.js:1825 peeriusShowWidgets() und angepasst
 *
 */
export function peeriusShowWidgets(widgetDataList: WidgetDataList, langCode: string) {
    // console.log('PeeriusApi.ts - peeriusShowWidgets', widgetDataList, langCode);

    if (!(widgetDataList instanceof Array && widgetDataList.length)) {
        // console.info('PeeriusApi - widgetDataList is empty or not an array.', {widgetDataList});
        return;
    }

    const widgetConfigMapping: Map<string, WidgetConfig> = new Map([
        ['basketjson_ip', {
            selector: ".js_peerius_recommendations_cart",
            template: "productSliderCart"
        }],
        ['categoryjson_ip', {
            selector: ".js_peerius_recommendations_category",
            template: "productSlider"
        }],
        ['homejson_ip', {
            selector: ".js_peerius_recommendations_home",
            template: "productSlider"
        }],
        ['homejson_ip2', {
            selector: ".js_peerius_recommendations_home2",
            template: "productSlider"
        }],
        ['productjson_ip', {
            selector: ".js_peerius_recommendations_product",
            template: "productSlider"
        }],
        ['wishlistjson_ip', {
            selector: ".js_peerius_recommendations_wishlist",
            template: "productSlider"
        }],
        ['productjson2_ip', {
            selector: ".js_peerius_recommendations_product_orange",
            template: "productSlider"
        }]
    ]);

    widgetDataList.forEach((widgetData) => {
        if (!(widgetData
            && widgetData.position
            && widgetConfigMapping.has(widgetData.position)
            && widgetData.recs
            && widgetData.recs.length
        )) {
            // console.warn('PeeriusApi - widgetData is not valid.', {widgetData});
            return;
        }
        // console.debug('PeeriusApi - processing', widgetData.position, {widgetData});

        const widgetConfig = widgetConfigMapping.get(widgetData.position);
        if (widgetConfig && document.querySelector(widgetConfig.selector)) {
            // console.debug('PeeriusApi - widgetConfig found for this position:', widgetData.position);

            let elementList: any[] = [];

            widgetData.recs.forEach((rec) => {
                if (rec.refCode && rec.id) {
                    elementList.push({
                        "shop_id": rec.refCode,
                        "foreign_id": rec.id
                    });
                }
            });

            if (elementList.length) {
                const url = new URL('/json.php', window.location.origin);
                url.searchParams.set('service', 'getRecommendationMarkup');
                url.searchParams.set('pres', window.etData.presenceId.toString());
                url.searchParams.set('context_vw_type', window.etData.vwType);
                url.searchParams.set('context_vw_name', window.etData.vwName);
                url.searchParams.set('context_vw_id', window.etData.vwId.toString());
                url.searchParams.set('lang', window.etData.lang);
                url.searchParams.set('list', JSON.stringify(elementList));
                url.searchParams.set('template', widgetConfig.template);
                // console.log('PeeriusApi - fetch url', url.toString());

                fetch(url)
                    .then(response => response.json())
                    .then((data: any) => {
                        // console.log('Peerius Api - fetchSuccessHandler', {data, widgetConfig, elementList});
                        if (data && data.success && "html" in data) {
                            const container = document.querySelector(widgetConfig.selector) as HTMLElement;

                            if (!(container instanceof HTMLElement)) {
                                throw new Error('PeeriusApi - container not found for selector ' + widgetConfig.selector);
                            }

                            container.innerHTML = data.html;
                            container.closest('.js_peerius_recommendations_container')?.classList.remove('d-none');

                            // peerius click handlers
                            container.querySelectorAll('[data-peerius-id]').forEach(d => {
                                if (!(d instanceof HTMLElement)) return;
                                d.addEventListener('click', () => {
                                    if (window.Peerius && window.Peerius.smartRecsClick) {
                                        window.Peerius.smartRecsClick(d.dataset.peeriusId);
                                    }
                                });
                            });

                            // take care of sliders and slider buttons
                            EtSlider.initAllSliders();
                            // regular click handlers
                            initAllElementArtikel();

                            // resizePeeriusWidgetsProduct();
                            // $(window).on('resize', resizePeeriusWidgetsProduct);

                            if ("tracking" in data) {
                                let peeriusTrackingData = JSON.parse(data.tracking);
                                if (typeof peeriusTrackingData.add !== "undefined") {
                                    additional_data.add = {...additional_data.add, ...peeriusTrackingData.add};
                                }
                                if (typeof peeriusTrackingData.view !== "undefined") {
                                    additional_data.view = {...additional_data.view, ...peeriusTrackingData.view};
                                }
                                if (typeof peeriusTrackingData.wish !== "undefined") {
                                    additional_data.wish = {...additional_data.wish, ...peeriusTrackingData.wish};
                                }
                            }
                        }
                    })
                    .catch(error => console.error('Error:', error));
            }
        } else {
            // console.info('PeeriusApi - widgetConfig not found for this position:', widgetData.position);
        }
    });
}
